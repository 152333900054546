import React, { useState } from 'react';
import { Button, Grid, makeStyles, TextField } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import './login.css';
import { Divider, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/28East-alpha2.png';
import useAuth from '../../hooks/useAuth';


const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#414A51"
    },
    "& .MuiInputLabel-outlined": {
      color: "#414A51"
    },
  },
  textField: {
    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px',
    [theme.breakpoints.up('sm')]: {
      width: '390px',
    },

  },
}));

const theme = createTheme({
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '14px'
        }
      },
    },
  }
})


export default function Login() {
  const { login, loginWithGoogle } = useAuth();
  const [pass, setPass] = useState('')
  const [email, setEmail] = useState('')
  const [passwordInput, setPasswordInput] = useState('password')
  const [errorMessage, setErrorMessage] = useState('')


  const classes = useStyles()
  const navigate = useNavigate()

  const onClick = () => {
    login(email, pass).then(() => {
      navigate('/', { replace: true })
      console.log("Successfully logged in")
    }).catch(error => {
      setErrorMessage('Wrong email or password')
    })
  }

  const setPasswordType = (event) => (!event.target.checked) ? setPasswordInput('password') : setPasswordInput("text")

  const Clear = () => {
    setEmail(null)
    setPass(null)
  }

  return (
    <div className={classes.root}>
      <Grid
        justifyContent="center"
        container
        direction="column"
        alignItems="center"
        style={{ minHeight: '100vh', padding: '5px' }}
      >
        <Paper className="rounded border border-zinc-300" elevation={0} variant='outlined'>
          <ThemeProvider theme={theme}>
            <Grid container direction="column" alignItems="center" justifyContent="center">
              <img id="logo" src={logo} width="140" alt="28East Logo"  className='pt-10'/>
              <Typography className='text-xl font-semibold text-28east-gray'>28East Pricing Calculator</Typography>
              <Typography className='text-base text-28east-red'>for the Google Maps Platform</Typography>
              <form className={classes.form}>
                <Divider className='bg-zinc-300'/>
                <Typography className='py-5 text-lg font-bold text-center text-28east-gray'>Sign In</Typography>
                <div className={classes.content}>
                  <div>
                    <div className='flex flex-row items-center mb-3.5'>
                      <AccountCircleIcon style={{ color: '#a1a1aa', marginRight: '10px', width: '20px' }}/>
                      <TextField
                        className={classes.textField}
                        error={errorMessage !== ''}
                        helperText={errorMessage === "" ? '' : errorMessage}
                        required
                        id="outlined-required-email"
                        label="Email"
                        type='text'
                        variant="outlined"
                        size="small"
                        onChange={(event) => {
                          setEmail(event.target.value)
                        }}
                      />
                    </div>
                    <div className='flex flex-row items-center mb-1.5'>
                      <LockIcon style={{ color: '#a1a1aa', marginRight: '10px', width: '20px' }}/>
                      <TextField
                        className={classes.textField}
                        error={errorMessage !== ''}
                        helperText={errorMessage === "" ? '' : errorMessage}
                        required
                        id="outlined-required-password"
                        label="Password"
                        variant="outlined"
                        size="small"
                        type={passwordInput}
                        onChange={(event) => {
                          setPass(event.target.value)
                        }}
                      />
                    </div>
                  </div>
                  <FormGroup className='flex items-end pb-5'>
                    <FormControlLabel sx={{color: '#71717a', marginRight: '0px'}}  control={<Checkbox color="secondary" onChange={setPasswordType} style={{paddingRight: '4px'}}/>} label="Show Password"/>
                  </FormGroup>
                  <Divider className='bg-zinc-300'/>
                  <div className='flex justify-center'>
                    <Button onClick={onClick} variant="contained" disableElevation className="mt-5 text-white capitalize bg-28east-red hover:bg-red-800">Sign In</Button>
                  </div>
                </div>
              </form>
            </Grid>
          </ThemeProvider>
        </Paper>
      </Grid>
    </div>
  );
}

