import axios from 'axios'

// can add additional html builders
// should make this a context object to return a dialog popup
// want the dialog to be generic and not talk about the data table

export const shareEmail = async (formData) => {
  try {

    const params = {}

    if(formData.to?.length > 0) {
      params.to = formData.to
    } else {
      throw Error('error/no-sender')
    }

    if(formData.cc?.length > 0) {
      params.cc = formData.cc
    }

    if(formData.subject?.length > 0) {
      params.subject = formData.subject
    } else {
      throw Error('error/no-subject')
    }

    if(formData.html?.length > 0) {
      params.html = formData.html
    }

    if(formData.data) {
      params.data = formData.data
    }

    const response = await axios.post(process.env.REACT_APP_EMAIL_API_URL, params, {
      headers: {  
        'x-api-key': process.env.REACT_APP_EMAIL_API_KEY
      }
    })

    return response

  } catch (error) {
    throw Error(error)
  }
}