

function Estimator(productArray, rate) {
    const exchangeRate = rate;
    function getMapsDiscount() {
        return { dollarDiscount: 200, randDiscount: Math.floor(200 * exchangeRate) };
    };

    const calculateTotals = () => {
        if (productArray) {
            let totalDollars = 0
            let totalRands = 0
            let totalDiscountedDollars = 0
            let totalDiscountedRands = 0

            // console.log( "productsObj in calculateTotals", productsObj )
            productArray.forEach((product) => {
                const { discountCosts, discountRand, actualCosts, actualRand } = product.costs;
                totalDollars += actualCosts;
                totalRands += actualRand;
                totalDiscountedDollars += discountCosts;
                totalDiscountedRands += discountRand;

            })

            return {
                totalDollars,
                totalRands,
                totalDiscountedDollars,
                totalDiscountedRands,
            };
        }
        return false;
    };

    const calculateFreeTierDiscount = (
        totalDollars,
        totalRands,
        totalDiscountedDollars,
        totalDiscountedRands
    ) => {
        const { dollarDiscount, randDiscount } = getMapsDiscount();

        let finalDollars = totalDollars - dollarDiscount;
        let finalRands = totalRands - randDiscount;
        let finalDiscountedDollars = totalDiscountedDollars - dollarDiscount;
        let finalDiscountedRands = totalDiscountedRands - randDiscount;

        if (finalDollars < 0) finalDollars = 0;
        if (finalRands < 0) finalRands = 0;
        if (finalDiscountedDollars < 0) finalDiscountedDollars = 0;
        if (finalDiscountedRands < 0) finalDiscountedRands = 0;

        // console.log('CalculateFreeTierDiscount | finalDollars, finalRands,finalDiscountedDollars,finalDiscountedRands,', finalDollars,finalRands,finalDiscountedDollars,finalDiscountedRands)

        return {
            finalDollars,
            finalRands,
            finalDiscountedDollars,
            finalDiscountedRands,
        };
    };


    const estimatorObj = [];
    const generateEstimateItems = () => {

        function sortFunc(a, b) {
            const sortingArr = ['b', 'c', 'b', 'b', 'c', 'd'];
            return sortedArray.indexOf(a[1]) - sortedArray.indexOf(b[1]);
        }
        const sortedArray = ['Maps', 'Routes', 'Places']
        productArray.sort((a, b) => sortedArray.indexOf(a.productCategory) - sortedArray.indexOf(b.productCategory))

        // productArray.sort(sortFunc)
        productArray.forEach((product) => {
            const { discountCosts, discountRand, actualCosts, actualRand } = product.costs;
            const { productCategory, name, usage, description } = product;
            console.log('GenerateEstimateItems product', product)

            estimatorObj.push({
                key: name,
                productCategory,
                productApi: description,
                name,
                usage,
                googleDirectDollars: actualCosts,
                googleDirectRands: actualRand,
                eastDollarDiscount: discountCosts,
                eastRandDiscount: discountRand,
            }
            );
        })


        const { totalDollars, totalRands, totalDiscountedDollars, totalDiscountedRands } = calculateTotals();
        // console.log("totalDollars, totalRands, totalDiscountedDollars, totalDiscountedRands",totalDollars, totalRands, totalDiscountedDollars, totalDiscountedRands)
        const { finalDollars, finalRands, finalDiscountedDollars, finalDiscountedRands } = calculateFreeTierDiscount(totalDollars,
            totalRands, totalDiscountedDollars, totalDiscountedRands);
        const { dollarDiscount, randDiscount } = getMapsDiscount();

        estimatorObj.push({
            key: "FreeTier",
            productCategory: "Maps Free Tier (Credit)",
            productApi: "",
            usage: "",
            googleDirectDollars: `-${dollarDiscount}`,
            googleDirectRands: `-${randDiscount}`,
            eastDollarDiscount: `-${dollarDiscount}`,
            eastRandDiscount: `-${randDiscount}`
        }
        );

        estimatorObj.push({
            key: "Totals",
            productCategory: "Totals",
            productApi: "",
            usage: "",
            googleDirectDollars: finalDollars,
            googleDirectRands: finalRands,
            eastDollarDiscount: finalDiscountedDollars,
            eastRandDiscount: finalDiscountedRands
        }
        );
    };
    generateEstimateItems()
    return estimatorObj
}

export default Estimator;
