import { useCallback, useState } from 'react'

const useToggle = (initialState = false) => {
  // Initialize the state
  const [state, setState] = useState(initialState)
  
  // Define and memorize toggler function in case we pass down the component,
  // This function change the boolean value to it's opposite value
  // eslint-disable-next-line no-shadow
  const toggle = useCallback(() => setState(state => !state), [])
  
  return [state, toggle]
}

export default useToggle