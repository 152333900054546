import PropTypes from 'prop-types';
import { createContext, useState, useMemo, useCallback, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, query, getDocs } from 'firebase/firestore';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, signOut, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { dialogContentClasses } from '@mui/material';
import { elementAcceptingRef } from '@mui/utils';
import { firebaseConfig } from '../config/firebaseConfig';
import useLocalStorage from '../hooks/useLocalStorage';
import { backupData } from './dataBackupObject';

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);

const AuthContext = createContext({
  method: 'firebase',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  loginWithGoogle: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};
function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const auth = getAuth(firebaseApp);
  const [inputBoxData, setInputBoxData] = useState();
  const [outputData, setOutputData] = useState([]);

  const [placesInputData, setPlacesInputData] = useLocalStorage('placesInputData', []);
  const [newPlacesInputData, setNewPlacesInputData] = useLocalStorage('newPlacesInputData', []);
  const [mapsInputData, setMapsInputData] = useLocalStorage('mapsInputData', []);
  const [routesInputData, setRoutesInputData] = useLocalStorage('routesInputData', []);
  const [environmentInputData, setEnvironmentInputData] = useLocalStorage('environmentInputData', []);
  const [mapTilesInputData, setMapTilesInputData] = useLocalStorage('mapTilesInputData', []);

  useEffect(() => {
    if (inputBoxData?.maps) {
      const mapsFormikValues = generateFormikInitialValues(inputBoxData.maps, 'Maps');
      setMapsInputData(mapsFormikValues);
    }

    if (inputBoxData?.routes) {
      const routesFormikValues = generateFormikInitialValues(inputBoxData.routes, 'Routes');
      setRoutesInputData(routesFormikValues);
    }
    if (inputBoxData?.places) {
      const placesFormikValues = generateFormikInitialValues(inputBoxData.places, 'Places');
      setPlacesInputData(placesFormikValues);
    }
    if (inputBoxData?.newPlaces) {
      const newPlacesFormikValues = generateFormikInitialValues(inputBoxData.newPlaces, 'New Places');
      setNewPlacesInputData(newPlacesFormikValues);
    }
    if (inputBoxData?.environment) {
      const environmentFormiKValues = generateFormikInitialValues(inputBoxData.environment, 'Environment');
      setEnvironmentInputData(environmentFormiKValues);
    }
    if (inputBoxData?.mapTiles) {
      const mapTilesFormiKValues = generateFormikInitialValues(inputBoxData.mapTiles, 'Map Tiles');
      setMapTilesInputData(mapTilesFormiKValues);
    }
  }, [inputBoxData, inputBoxData?.maps, inputBoxData?.places, inputBoxData?.newPlaces, inputBoxData?.routes, inputBoxData?.environment, inputBoxData?.mapTiles]);

  const getData = useCallback(async () => {
    console.log('FirebaseContext getData | inputBoxData', inputBoxData);

    const dataCollection = {};

    const querySnapshot = await getDocs(collection(db, 'billingcalculator'));

    querySnapshot.forEach((doc) => {
      if (doc.id === 'Routes') {
        const routes = doc.data();
        dataCollection.routes = routes;
      } else if (doc.id === 'Maps') {
        const maps = doc.data();
        dataCollection.maps = maps;
      } else if (doc.id === 'Places') {
        const places = doc.data();
        dataCollection.places = places;
      } else if (doc.id === 'NewPlaces') {
        const newPlaces = doc.data();
        dataCollection.newPlaces = newPlaces;
      }  else if (doc.id === 'Environment') {
        const environment = doc.data();
        dataCollection.environment = environment;
      } else if (doc.id === 'MapTiles') {
        const mapTiles = doc.data();
        dataCollection.mapTiles = mapTiles;
      }
    });

    setInputBoxData(dataCollection);

    console.log('FirebaseContext getData | dataCollection', dataCollection);
    return dataCollection;
  }, []);

  const generateFormikInitialValues = (inputData, category = '') => {
    const generatedInputArray = [];

    Object.entries(inputData).forEach(([key, value], index) => {
      generatedInputArray.push({
        name: key,
        productCategory: category,
        description: value.name,
        calculationValue: '',
        order: value.order
      });
    });

    generatedInputArray.sort((a, b) => {
      const keyA = a.order;
      const keyB = b.order;
      // Compare the 2 orders
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });

    return generatedInputArray;
  };

  const login = (email, password) => {
    const user = new Promise((resolve, reject) => {
      signInWithEmailAndPassword(auth, email, password)
        .then((user) => {
          setUser(user);
          setIsLoggedIn(true);
          resolve(user);
        })
        .catch((error) => {
          console.log(error.message);
          reject();
        });
    });
    return user;
  };

  const loginWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth(firebaseApp);

    const user = new Promise((resolve, reject) => {
      signInWithPopup(auth, provider)
        .then((result) => {
          if (!validAccount(result.user.email)) {
            auth.currentUser.delete();
            return;
          }
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          const { user } = result;
          setUser(user);
          setIsLoggedIn(true);
          resolve(user);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          const { email } = error;
          const credential = GoogleAuthProvider.credentialFromError(error);
          reject();
        });
    });
  };

  const validAccount = (userEmail) => userEmail.split('@')[1] === '28east.co.za';

  const logout = async () => {
    await signOut();
    setIsLoggedIn(false);
  };

  const resetPassword = async (email) => {
    await sendPasswordResetEmail(email);
  };

  const contextValues = useMemo(
    () => ({
      method: 'firebase',
      user,
      isLoggedIn,
      setIsLoggedIn,
      login,
      logout,
      resetPassword,
      loginWithGoogle,
      getData,
      inputBoxData,
      environmentInputData,
      mapTilesInputData,
      placesInputData,
      newPlacesInputData,
      mapsInputData,
      routesInputData,
      outputData,
      setOutputData
    }),
    [user, isLoggedIn, login, loginWithGoogle, getData, inputBoxData, environmentInputData, mapTilesInputData, placesInputData, newPlacesInputData, mapsInputData, routesInputData, outputData]
  );

  return <AuthContext.Provider value={contextValues}>{children}</AuthContext.Provider>;
}
export { AuthContext, AuthProvider };
