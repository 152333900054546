import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

export default function AccountMenu({ routes }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { logout, user } = useAuth()
  const navigate = useNavigate()

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      logout()
      navigate('/Login', { replace: true })
    } catch (error) {
      console.log(error.message)
    }
  }

  return (
    user && user.user.email && (
      <div>
        <Box sx={{ display: 'flex', justifyContent: 'end' }} className="pt-6 pr-7">
          <Tooltip title="Account settings" placement="bottom-end">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar className='w-7 h-7 bg-transparent'><AccountCircleIcon className='w-7 h-7 fill-zinc-500 hover:fill-28east-gray' /></Avatar>
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 1,
            sx: {
              bgcolor: '#fff',
              border: '1px solid #d4d4d8',
              overflow: 'visible',
              filter: 'none',
              mt: 0.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                borderLeft: '1px solid #d4d4d8',
                borderTop: '1px solid #d4d4d8',
                bgcolor: '#fff',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem>
            <ListItemIcon>
              <AccountCircleIcon style={{ color: '#a1a1aa' }} />
            </ListItemIcon>
            <Typography style={{ color: '#414A51' }}>{user.user.email}</Typography>
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout style={{ color: '#a1a1aa' }} />
            </ListItemIcon>
            <Typography style={{ color: '#414A51' }}>Logout</Typography>
          </MenuItem>
        </Menu>
      </div >
    )
  );
}
