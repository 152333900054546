import { useCallback, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CalculationInputForm from './CalculationInputForm';
import styles from './CalculationInputPanel.module.css';
import Calculator from '../../../services/CalculationService';
import useAuth from '../../../hooks/useAuth';
import useData from '../../../hooks/useData';
import Estimator from '../../../services/Estimator';

function CalculationInputPanel({ handleSubmit, handleClear }) {
  const [expanded, setExpanded] = useState(false);
  const { getData } = useAuth();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const formData = useCallback(async () => getData(), []);

  // const handleSubmit = async (values, { setSubmitting }) => {
  //   const data = await getData()
  //   setSubmitting(false);
  //   const productArray = await Calculator(values, data, exchangeRate);
  //   const outPutArray = await Estimator(productArray, exchangeRate)
  //   setOutputData(outPutArray)
  //   console.log(JSON.stringify(values));

  // };

  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} elevation={0} variant='outlined' className='p-2 mb-5 border-zinc-300'>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1bh-content' id='panel1bh-header'>
          <div>
            <Typography className='text-xl font-bold text-28east-gray'>Estimated usage input section</Typography>
            <Typography className='text-base text-28east-gray'>Input your estimated usage into the relevant sections below then start the calculation.</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className=''>
          <CalculationInputForm handleSubmit={handleSubmit} handleClear={handleClear} formData={formData} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default CalculationInputPanel;
