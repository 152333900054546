import { Formik, Form, FastField, FieldArray } from 'formik';
import { Button, Grid, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material';

import { useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import CalculationInput from './CalculationInput';
import CalculationInputPanel from './CalculationInputPanel';
import useData from '../../../hooks/useData';

function CalculationInputForm({ handleSubmit, handleClear }) {
  const { placesInputData, newPlacesInputData, mapsInputData, routesInputData, environmentInputData, mapTilesInputData } = useAuth();
  const { setExchangeRate, exchangeRate } = useData();

  const [exchangeRateValue, setExchangeRateValue] = useState(parseFloat(exchangeRate).toFixed(2));

  useEffect(() => {
    setExchangeRate(exchangeRateValue);
  }, [exchangeRateValue]);

  return (
    <Formik
      initialValues={{ places: [...placesInputData], newPlaces: [...newPlacesInputData], maps: [...mapsInputData], routes: [...routesInputData], environment: [...environmentInputData], mapTiles: [...mapTilesInputData] }}
      onSubmit={handleSubmit}
      onReset={handleClear}
    >
      {({ values, errors }) => (
        <Form>
          <Grid container spacing={3} >
            <Grid container spacing={3} className='ml-1'>
              <Grid item xs={12} md={3} className='pt-5 drop-shadow-md'>
                <Typography className='rounded-t bg-zinc-100 p-5 pb-2.5 text-xl font-bold text-28east-gray'>Maps</Typography>
                <FieldArray name='maps'>
                  {(arrayHelpers) => (
                    <Grid container className='max-h-400 overflow-scroll rounded-b bg-zinc-100 p-5 pt-2.5'>
                      {values.maps.map((currentValue, index) => (
                        <FastField
                          key={currentValue.name}
                          name={`maps[${index}].calculationValue`}
                          component={CalculationInput}
                          fullWidth
                          text={values.maps[index].description}
                          internalKey={currentValue.name}
                          size='small'
                          sx={{
                            '& .MuiOutlinedInput-root.Mui-focused': {
                              '& > fieldset': {
                                border: '2px solid #414A51'
                              }
                            }
                          }}
                        />
                      ))}
                      <Typography className='mt-1 text-xs italic text-28east-gray'>* Price change, free of charge, starting 07/01/2020.</Typography>
                    </Grid>
                  )}
                </FieldArray>
              </Grid>
              <Grid item xs={12} md={3} className='pt-5'>
                <Typography className='rounded-t bg-zinc-100 p-5 pb-2.5 text-xl font-bold text-28east-gray'>Routes</Typography>
                <FieldArray name='routes' className='min-h-400 bg-zinc-100'>
                  {(arrayHelpers) => (
                    <Grid container className='max-h-400 overflow-scroll rounded-b bg-zinc-100 p-5 pt-2.5'>
                      {values.routes.map((currentValue, index) => (
                        <FastField
                          key={currentValue.name}
                          name={`routes[${index}].calculationValue`}
                          component={CalculationInput}
                          fullWidth
                          text={values.routes[index].description}
                          internalKey={currentValue.name}
                          size='small'
                          sx={{
                            '& .MuiOutlinedInput-root.Mui-focused': {
                              '& > fieldset': {
                                border: '2px solid #414A51'
                              }
                            }
                          }}
                        />
                      ))}
                      <Typography className='mt-1 text-xs italic text-28east-gray'>** Limited access.</Typography>
                    </Grid>
                  )}
                </FieldArray>
              </Grid>
              <Grid item xs={12} md={3} className='pt-5'>
                <Typography className='rounded-t bg-zinc-100 p-5 pb-2.5 text-xl font-bold text-28east-gray'>Places</Typography>
                <FieldArray
                  name='places'
                  sx={{
                    '& .MuiFormControl-root': {
                      '& .MuiTextField-root': {
                        marginBottom: '1reme'
                      }
                    }
                  }}
                >
                  {(arrayHelpers) => (
                    <Grid container className='max-h-400 overflow-scroll rounded-b bg-zinc-100 p-5 pt-2.5'>
                      {values.places.map((currentValue, index) => (
                        <FastField
                          key={currentValue.name}
                          name={`places[${index}].calculationValue`}
                          component={CalculationInput}
                          fullWidth
                          text={values.places[index].description}
                          internalKey={currentValue.name}
                          size='small'
                          style={{}}
                          sx={{
                            '& .MuiOutlinedInput-root.Mui-focused': {
                              '& > fieldset': {
                                border: '2px solid #414A51'
                              }
                            }
                          }}
                        />
                      ))}
                      <Typography className='mt-1 text-xs italic text-28east-gray'>
                        *** This SKU will show up as $0 on the bill, but you will be charged a Places Details API call and incur cost under that SKU.
                      </Typography>
                    </Grid>
                  )}
                </FieldArray>
              </Grid>
              <Grid item xs={12} md={3} className='pt-5'>
                <Typography className='rounded-t bg-zinc-100 p-5 pb-2.5 text-xl font-bold text-28east-gray'>New Places</Typography>
                <FieldArray
                  name='newPlaces'
                  sx={{
                    '& .MuiFormControl-root': {
                      '& .MuiTextField-root': {
                        marginBottom: '1reme'
                      }
                    }
                  }}
                >
                  {(arrayHelpers) => (
                    <Grid container className='max-h-400 overflow-scroll rounded-b bg-zinc-100 p-5 pt-2.5'>
                      {values.newPlaces.map((currentValue, index) => (
                        <FastField
                          key={currentValue.name}
                          name={`newPlaces[${index}].calculationValue`}
                          component={CalculationInput}
                          fullWidth
                          text={values.newPlaces[index].description}
                          internalKey={currentValue.name}
                          size='small'
                          style={{}}
                          sx={{
                            '& .MuiOutlinedInput-root.Mui-focused': {
                              '& > fieldset': {
                                border: '2px solid #414A51'
                              }
                            }
                          }}
                        />
                      ))}
                      {/* <Typography className='mt-1 text-xs italic text-28east-gray'>
                        *** This SKU will show up as $0 on the bill, but you will be charged a Places Details API call and incur cost under that SKU.
                      </Typography> */}
                    </Grid>
                  )}
                </FieldArray>
              </Grid>
              <Grid item xs={12} md={3} className='pt-5 '>
                <Typography className='rounded-t bg-zinc-100 p-5 pb-2.5 text-xl font-bold text-28east-gray'>Environment</Typography>
                <FieldArray name='environment'>
                  {(arrayHelpers) => (
                    <Grid container className='max-h-400 min-h-400 rounded-b bg-zinc-100 p-5 pt-2.5 '>
                      {values.environment.map((currentValue, index) => (
                        <FastField
                          key={currentValue.name}
                          name={`environment[${index}].calculationValue`}
                          component={CalculationInput}
                          fullWidth
                          text={values.environment[index].description}
                          internalKey={currentValue.name}
                          size='small'
                          sx={{
                            '& .MuiOutlinedInput-root.Mui-focused': {
                              '& > fieldset': {
                                border: '2px solid #414A51'
                              }
                            },
                            height: 'fit-content'
                          }}
                        />
                      ))}
                    </Grid>
                  )}
                </FieldArray>
              </Grid>
              <Grid item xs={12} md={3} className='pt-5 '>
                <Typography className='rounded-t bg-zinc-100 p-5 pb-2.5 text-xl font-bold text-28east-gray'>Map Tiles</Typography>
                <FieldArray name='mapTiles'>
                  {(arrayHelpers) => (
                    <Grid container className='max-h-400 min-h-400 rounded-b bg-zinc-100 p-5 pt-2.5 '>
                      {values.mapTiles.map((currentValue, index) => (
                        <FastField
                          key={currentValue.name}
                          name={`mapTiles[${index}].calculationValue`}
                          component={CalculationInput}
                          fullWidth
                          text={values.mapTiles[index].description}
                          internalKey={currentValue.name}
                          size='small'
                          sx={{
                            '& .MuiOutlinedInput-root.Mui-focused': {
                              '& > fieldset': {
                                border: '2px solid #414A51'
                              }
                            },
                            height: 'fit-content'
                          }}
                        />
                      ))}
                    </Grid>
                  )}
                </FieldArray>
              </Grid>
            </Grid>
            <Grid container spacing={3} className='ml-1'>
              <Grid item xs={2} className='mt-10 flex flex-col justify-start pt-0'>
                <TextField
                  size='small'
                  label='Exchange Rate'
                  variant='outlined'
                  type='number'
                  value={exchangeRateValue}
                  onChange={(e) => {
                    setExchangeRateValue(parseFloat(e.target.value));
                  }}
                  onBlur={() => {
                    setExchangeRateValue(parseFloat(exchangeRate).toFixed(2));
                  }}
                  className='border-28east-gray text-28east-gray'
                  InputProps={{
                    startAdornment: <InputAdornment>R</InputAdornment>
                  }}
                />
              </Grid>
              <Grid item xs={10} className='mt-10 flex flex-row justify-end pt-0'>
                <Button type='submit' variant='contained' disableElevation className='mr-5 bg-28east-red capitalize text-white hover:bg-red-800'>
                  Calculate Estimate
                </Button>
                <Button type='reset' variant='outlined' className='border-28east-gray capitalize text-28east-gray hover:bg-28east-gray hover:text-white'>
                  Clear
                </Button>
                {/* <Typography>{JSON.stringify({ values, errors }, null, 4)}</Typography> */}
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default CalculationInputForm;
