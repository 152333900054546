let localDataSource = {};
let totalDollars = 0;
let totalDiscountedDollars = 0;
let totalRands = 0;
let totalDiscountedRands = 0;
let exchangeRateLocal = 0;

const getServices = () => {
  const collection = localDataSource;
  const services = Object.keys(collection);
  return services;
};

const ResetUsage = () => {
  const collection = localDataSource;
  collection.forEach((key, value) => {
    const service = collection[key];
    service.forEach((key, value) => {
      if (service) {
        service[key].usage = '';
      }
    });
  });
};

const CalculateDiscountedCost = (product) => {
  let discountCost = 0;
  let randsToDollar = 0;
  let discountRands = 0;

  if(product && (product.name === '2DMapTiles' || product.name === 'streetViewTiles')) {
    // This can be improved with propper if statements and a getTier(amountOfHits) function
    discountCost += CalculateTierCost(0, 1000000, product.costPerThousand[0], parseFloat(product.calculationValue.replace(/,/g, '')));
    discountCost += CalculateTierCost(1000001, 5000000, product.costPerThousand[1], parseFloat(product.calculationValue.replace(/,/g, '')));
    discountCost += CalculateTierCost(5000001, 10000000, product.costPerThousand[2], parseFloat(product.calculationValue.replace(/,/g, '')));
    discountCost += CalculateTierCost(10000001, 50000000, product.costPerThousand[3], parseFloat(product.calculationValue.replace(/,/g, '')));
    discountCost += CalculateTierCost(50000001, 100000000, product.costPerThousand[4], parseFloat(product.calculationValue.replace(/,/g, '')));
    discountCost += CalculateTierCost(100000001, 200000000, product.costPerThousand[5], parseFloat(product.calculationValue.replace(/,/g, '')));
    discountCost += CalculateTierCost(200000001, -1, product.costPerThousand[6], parseFloat(product.calculationValue.replace(/,/g, '')));

    discountCost = Math.ceil(discountCost);
    randsToDollar = exchangeRateLocal;
    discountRands = Math.ceil(discountCost * randsToDollar);
  }

  if (product && !(product.name === '2DMapTiles' || product.name === 'streetViewTiles')) {
    // This can be improved with propper if statements and a getTier(amountOfHits) function
    discountCost += CalculateTierCost(0, 100000, product.costPerThousand[0], parseFloat(product.calculationValue.replace(/,/g, '')));
    discountCost += CalculateTierCost(100000, 500000, product.costPerThousand[1], parseFloat(product.calculationValue.replace(/,/g, '')));
    discountCost += CalculateTierCost(500000, 1000000, product.costPerThousand[2], parseFloat(product.calculationValue.replace(/,/g, '')));
    discountCost += CalculateTierCost(1000000, 5000000, product.costPerThousand[3], parseFloat(product.calculationValue.replace(/,/g, '')));
    discountCost += CalculateTierCost(5000000, 10000000, product.costPerThousand[4], parseFloat(product.calculationValue.replace(/,/g, '')));
    discountCost += CalculateTierCost(10000000, 20000000, product.costPerThousand[5], parseFloat(product.calculationValue.replace(/,/g, '')));
    discountCost += CalculateTierCost(20000000, -1, product.costPerThousand[6], parseFloat(product.calculationValue.replace(/,/g, '')));

    discountCost = Math.ceil(discountCost);
    randsToDollar = exchangeRateLocal;
    discountRands = Math.ceil(discountCost * randsToDollar);
  }
  return {
    discountCost,
    discountRands
  };
};

const CalculateTierCost = (minTier, maxTier, tierCost, usage) => {
  let cost = 0;

  if (maxTier !== -1 && usage > maxTier && maxTier > 0) {
    cost = ((maxTier - minTier) / 1000) * tierCost;
  } else if ((usage <= maxTier || maxTier === -1) && usage > minTier) {
    const remaining = usage - minTier;
    cost = (remaining / 1000) * tierCost;
  }

  return cost;
};

const CalculateActualCost = (product) => {
  const randsToDollar = exchangeRateLocal;
  let actualCost = 0;
  let actualRands = 0;

  if(product && (product.name === '2DMapTiles' || product.name === 'streetViewTiles')) {
    actualCost += CalculateTierCost(0, 1000000, product.costPerThousand[0], parseFloat(product.calculationValue.replace(/,/g, '')));
    actualCost += CalculateTierCost(1000001, -1, product.costPerThousand[1], parseFloat(product.calculationValue.replace(/,/g, '')));

    actualCost = Math.ceil(actualCost);
    actualRands = Math.ceil(actualCost * randsToDollar);
  }

  if(product && !(product.name === '2DMapTiles' || product.name === 'streetViewTiles')) {
    actualCost += CalculateTierCost(0, 100000, product.costPerThousand[0], parseFloat(product.calculationValue.replace(/,/g, '')));
    actualCost += CalculateTierCost(100000, -1, product.costPerThousand[1], parseFloat(product.calculationValue.replace(/,/g, '')));

    actualCost = Math.ceil(actualCost);
    actualRands = Math.ceil(actualCost * randsToDollar);
  }

  // console.log('CalculateActualCost | ',actualCost, actualRands)

  return { actualCost, actualRands };
};

const CalculateCost = (product) => {
  const { actualCost, actualRands } = CalculateActualCost(product);
  const { discountCost, discountRands } = CalculateDiscountedCost(product);
  // let { discountedTotalCost, discountedTotalActualCost } = { totalCost };

  // Round the costs down to an int

  let calculatedDiscountCost = Math.floor(discountCost);
  let calculatedDiscountRands = Math.floor(discountRands);

  // Check if a negative value
  if (discountCost < 0) calculatedDiscountCost = 0;
  if (discountRands < 0) calculatedDiscountRands = 0;

  totalDollars += actualCost;
  totalRands += actualRands;
  totalDiscountedDollars += calculatedDiscountCost;
  totalDiscountedRands += calculatedDiscountRands;

  // console.log('CalculateCost | calculatedDiscountRands, calculatedDiscountRands, actualCost, actualRands', calculatedDiscountRands, calculatedDiscountRands, actualCost, actualRands)
  // console.log('CalculateCost | totalDollars', totalDollars)
  // console.log('CalculateCost | totalRands', totalRands)
  // console.log('CalculateCost | totalDiscountedDollars', totalDiscountedDollars)
  // console.log('CalculateCost | totalDiscountedRands', totalDiscountedRands)

  return { discountCost: calculatedDiscountCost, discountRands: calculatedDiscountRands, actualCost, actualRands };
};

function Calculator(values, dataSource, exchangeRate) {
  // console.log('Calculator | values', values)

  exchangeRateLocal = exchangeRate;

  localDataSource = dataSource;
  const services = getServices();
  const input = {
    maps: [],
    routes: [],
    places: [],
    newPlaces: [],
    environment: [],
    mapTiles: []
  };

  services.forEach((service) => {
    for (let i = 0; i < values[service].length; i += 1) {
      if (values[service][i].calculationValue) {
        const { costPerThousand } = dataSource[service][values[service][i].name];
        values[service][i].costPerThousand = costPerThousand;
        input[service].push(values[service][i]);
      }
    }
  });
  const productArray = [];
  const handleSubmit = () => {
    let productObj = {
      costs: '',
      usage: '',
      calculatorItems: {}
    };

    services.forEach((service) => {
      input[service].forEach((product) => {
        // console.log('product in Calculator', product)

        const calculationCost = CalculateCost(product);
        if (calculationCost) {
          const { discountCost, discountRands, actualCost, actualRands } = calculationCost;
          productObj = {
            name: product.name,
            description: product.description,
            productCategory: product.productCategory,
            usage: product.calculationValue.replace(/,/g, ''),
            costs: {
              discountCosts: discountCost,
              discountRand: discountRands,
              actualCosts: actualCost,
              actualRand: actualRands
            }
          };
          productArray.push(productObj);
        }
      });
    });
  };
  handleSubmit();
  return productArray;
}

export default Calculator;
