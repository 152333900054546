export const generateMinifiedHTMLTable = ({ title = '', columns = [], rows = [], user, exchangeRate }) => {
  const requestDateTime = new Date().toLocaleString();

  const formatCurrency = (value, currency) => new Intl.NumberFormat(currency === 'ZAR' ? 'en-ZA' : 'en-US', {
      style: 'currency',
      currency,
      maximumFractionDigits: 0
    }).format(value);

  const generateTableHeaders = (columns) => columns.map((col, index) => {
      const style = index >= columns.length - 2 ? 'style="color: #E31B23;"' : '';
      return `<th ${style}>${col.label}</th>`;
    }).join('');

  const generateTableRow = (row, columns, isLastRow = false) => columns.map((col, index) => {
      let rowValue = row[col.name] !== undefined ? row[col.name] : '';

      if (col.currency && rowValue) {
        rowValue = formatCurrency(rowValue, col.currency);
      }

      let cellStyle = '';
      if (isLastRow) {
        cellStyle = index >= columns.length - 2
          ? 'style="background-color: #e31b23; color: #fff;"'
          : 'style="background-color: #414a51; color: #fff;"';
      } else if (index >= columns.length - 2) {
        cellStyle = 'style="background-color: #f9d1d3;"';
      }

      return `<td ${cellStyle}>${rowValue}</td>`;
    }).join('');

  const generateTableRows = (rows, columns, message) => {
    if (rows.length > 0) {
      return rows.map((row, index) => {
        const isLastRow = index === rows.length - 1;
        return `<tr>${generateTableRow(row, columns, isLastRow)}</tr>`;
      }).join('');
    } 
      return `<tr><td colspan="${columns.length}" style="text-align: center;">Not Feasible</td></tr>`;
    
  };

  const tableHeaders = generateTableHeaders(columns);
  const tableRows = generateTableRows(rows, columns);

  return `
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <meta name="viewport" content="width=device-width,initial-scale=1">
      <style>
        .table_component {
          overflow: auto;
          width: 100%;
        }
        .table_component table {
          border-bottom: 1px solid #dededf;
          border-left: 1px solid #dededf;
          border-right: 1px solid #dededf;
          border-radius: 0 0 0 0;
          width: 100%;
          table-layout: auto;
          text-align: left;
        }
        .table_component caption {
          caption-side: top;
          text-align: left;
          padding: 8px 8px 8px 5px;
          border-radius: 8px 8px 0 0;
          border-top: 1px solid #dededf;
          border-left: 1px solid #dededf;
          border-right: 1px solid #dededf;
          border-bottom: 1px solid #dededf;
          font-weight: 500;
        }
        .table_component th,
        .table_component td {
          padding: 5px;
        }
        .table_component td {
          border-top: 1px solid #dededf;
          background-color: #fff;
          color: #000;
        }
        .table_message {
          font-family: Arial, sans-serif;
          margin-bottom: 8px;
          font-size: 16px;
          color: #333;
        }
        .request_datetime {
          font-family: Arial, sans-serif;
          font-size: 14px;
          color: #777;
          margin-bottom: 16px;
        }
      </style>
    </head>
    <body>
      <div class="table_message">
        <strong>${title}</strong>
      </div>
      <pre class="table_message">
Hello

We hope this email find you well.

Attached, you'll find the results of the 28East - Google Pricing Estimates we conducted on ${requestDateTime}

Request made by: ${user.email} 

Exchange Rate USD/ZAR: R ${exchangeRate}
      </pre>
      <div class="table_component" role="region" tabindex="0">
        <table>
          <caption>${title}</caption>
          <thead>
            <tr>${tableHeaders}</tr>
          </thead>
          <tbody>${tableRows}</tbody>
        </table>
      </div>
      <pre class="table_message">
Please let us know if you have any questions about the results or if you'd like to discuss the next steps.

Best regards

Google Pricing Estimates provided by 28East (Pty) Ltd.
      </pre>
    </body>
    </html>
  `;
};


export const hasError = (errorMessages) => Object.values(errorMessages).some(message => message.error === true)

 // Function to validate a single field based on a set of validation rules
export const validateField = (field, value, errorMessages, validations) => {

  validations.forEach((validation) => {
    if (validation.condition(value)) {
      // If validation fails, set the corresponding error message and flag
      errorMessages[field] = {
        message: validation.message,
        error: true,
      }
    }
  })

  // If no validation rule fails, set an empty error message and error flag to false
  errorMessages[field] = {
    message: '',
    error: false,
  }
}

export function isValidEmail(email='') {
  const regex =  /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  return regex.test(email)
}