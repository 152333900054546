import './App.css';
import { Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import ThemeConfig from './theme';
import Login from './components/LoginLayout/loginLayout';
import CalculatorLayout from './components/CalculatorLayout/CalculatorLayout';
import ProtectedRoute from './components/ProtectedRoute';
import useAuth from './hooks/useAuth';

function App() {
  const { getData } = useAuth()
  
  useEffect(() => {
    getData();
  }, [])

  return (
    <ThemeConfig>
      <Routes>
        <Route path="/login" element={<Login />}/>
        <Route element={<ProtectedRoute />}>
            <Route path='/' element={<CalculatorLayout />} />
        </Route>
      </Routes>
    </ThemeConfig>
  );
}

export default App;
