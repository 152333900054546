import PropTypes from 'prop-types';
import { createContext, useState, useMemo, useCallback, useEffect } from 'react';
import axios from 'axios';

const DataContext = createContext({
  method: 'data'
});

DataProvider.propTypes = {
  children: PropTypes.node
};

function DataProvider({ children }) {
  const [outputData, setOutputData] = useState([]);
  const [exchangeRate, setExchangeRate] = useState(17);

  const contextValues = useMemo(
    () => ({
      method: 'data',
      outputData,
      setOutputData,
      exchangeRate,
      setExchangeRate
    }),
    [outputData, setOutputData, setExchangeRate, exchangeRate]
  );

  return <DataContext.Provider value={contextValues}>{children}</DataContext.Provider>;
}
export { DataContext, DataProvider };
