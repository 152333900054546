import { Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import React from 'react';

function PageDescription() {  
  return (
    <Grid container className='flex justify-center'>

        <Grid item xs={10} className='flex justify-center mb-1'>
          <img className='w-36 md:w-44' alt="28East Logo" src='./28East-alpha2.png'/>
        </Grid>

      <Grid item xs={10}>
        <Typography className='text-3xl font-bold text-center text-28east-gray md:text-4xl'>28East Pricing Calculator</Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography className='text-xl text-center text-28east-red md:text-2xl'>for the Google Maps Platform</Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography className='mt-7 mb-16 text-base leading-6 text-center text-28east-gray'>
          Use our pricing calculator to get an estimate for your monthly costs. All unit costs are billed by usage
          according to the <Link className='text-blue-500 underline' href="https://mapsplatform.google.com/pricing/" underline="hover" target="_blank" rel="noopener noopener">Google Maps Platform rate card</Link>. The costs shown here include the volume discounts that are
          exclusively available through the Google Maps Partners. <br/> <Link className='text-blue-500 underline' href="https://www.28east.co.za/gcpaccount" underline="hover" target="_blank" rel="noopener noopener">Contact us</Link> to discuss volume discounts and the other benefits of local procurement.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default PageDescription;
