import { Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';
import {Mail} from '@mui/icons-material'
import EmailExportButton from '../../EmailExportButton/EmailExportButton'

const theme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          textAlign: 'end',
          color: '#A1A1AA',
          '&:hover': {
            color: '#414A51 !important',
            backgroundColor: 'transparent'
          }
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          textAlign: 'end',
          paddingRight: '10px !important'
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          textAlign: 'end',
          fontWeight: 'bold',
          lineHeight: '20px',
          fontSize: '15px'
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          textAlign: 'end',
          borderBottom: '1px solid #D4D4D8',
          color: '#414A51'
        },
      },
    },
    MUIDataTableBodyRow: {
      styleOverrides: {
        root: {
          textAlign: 'end',
          backgroundColor: '#fff',
          '&:hover': {
            backgroundColor: '#e4e4e7'
          }
        }
      }
    }
  },
});



function CalculationGrid({ data }) {

  
  const columns = [
    {
      name: 'productCategory',
      label: 'Product Category',
      options: {
        filter: true,
        sort: true,
        setCellProps: (value, rowIndex, columnIndex) => {
          if (rowIndex > data.length - 2) {
            return {
              style: {
                backgroundColor: `#414A51`,
                fontWeight: 'bold',
                color: '#fff'
              }
            }
          }
        },
      },
    },
    {
      name: 'productApi',
      label: 'Product API',
      options: {
        filter: true,
        sort: true,
        setCellProps: (value, rowIndex, columnIndex) => {
          if (rowIndex > data.length - 2) {
            return {
              style: {
                backgroundColor: `#414A51`,
                color: '#fff',
                fontWeight: 'bold'
              }
            }
          }
        },
      },
    },
    {
      name: 'usage',
      label: 'Usage',
      options: {
        filter: true,
        sort: false,
        setCellProps: (value, rowIndex, columnIndex) => {
          if (rowIndex > data.length - 2) {
            return {
              style: {
                backgroundColor: `#414A51`,
                color: '#fff'
              }
            }
          }
        },
        customBodyRender(value, tableMeta, updateValue) {
          if (value)
            return new Intl.NumberFormat().format(+value)
        }
      },
    },
    {
      name: 'googleDirectDollars',
      label: 'Google Direct ($)',
      // need for email sharing as a prefix
      currency: 'USD',
      options: {
        filter: true,
        sort: false,
        setCellProps: (value, rowIndex, columnIndex) => {
          if (rowIndex > data.length - 2) {
            return {
              style: {
                backgroundColor: `#414A51`,
                color: '#fff'
              }
            }
          }
        },
        customBodyRender(value, tableMeta, updateValue) {
          const returnedCurrencyNumber = new Intl.NumberFormat("en-US", {
            style: 'currency',
            currency: 'USD', maximumFractionDigits: 0
          }).format(value)
          return returnedCurrencyNumber
        }
      },
    },
    {
      name: 'googleDirectRands',
      label: 'Google Direct (R)',
      // need for email sharing as a prefix
      currency: 'ZAR',
      options: {
        filter: true,
        sort: false,
        setCellProps: (value, rowIndex, columnIndex) => {
          if (rowIndex > data.length - 2) {
            return {
              style: {
                backgroundColor: `#414A51`,
                color: '#fff'
              }
            }
          }
        },
        customBodyRender(value, tableMeta, updateValue) {
          const returnedCurrencyNumber = new Intl.NumberFormat("en-ZA", {
            style: 'currency',
            currency: 'ZAR', maximumFractionDigits: 0
          }).format(value)
          return returnedCurrencyNumber
        }
      },
    },
    {
      name: 'eastDollarDiscount',
      label: 'Cost With 28East Discount ($)',
      // need for email sharing as a prefix
      currency: 'USD',
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: value => ({
          style: {
            color: '#E31B23'
          }
        }),
        setCellProps: (value, rowIndex, columnIndex) => {
          if (rowIndex < data.length - 1) {
            return {
              style: {
                backgroundColor: `rgba(227, 27, 35, 0.2)`,
                fontWeight: 'bold',
              }
            }
          }
          return {
            style: {
              backgroundColor: `#E31B23`,
              fontWeight: 'bold',
              color: '#fff'
            }
          }
        },
        customBodyRender(value, tableMeta, updateValue) {
          const returnedCurrencyNumber = new Intl.NumberFormat("en-US", {
            style: 'currency',
            currency: 'USD', maximumFractionDigits: 0
          }).format(value)
          return returnedCurrencyNumber
        }
      },
    },
    {
      name: 'eastRandDiscount',
      label: 'Cost With 28East Discount (R)',
      // need for email sharing as a prefix
      currency: 'ZAR',
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: value => ({
          style: {
            color: '#E31B23'
          }
        }),
        setCellProps: (value, rowIndex, columnIndex) => {
          if (rowIndex < data.length - 1) {
            return {
              style: {
                backgroundColor: `rgba(227, 27, 35, 0.2)`,
                fontWeight: 'bold',
              }
            }
          }
          return {
            style: {
              backgroundColor: `#E31B23`,
              fontWeight: 'bold',
              color: '#fff'
            }
          }
        },
        customBodyRender(value, tableMeta, updateValue) {
          const returnedCurrencyNumber = new Intl.NumberFormat("en-ZA", {
            style: 'currency',
            currency: 'ZAR', maximumFractionDigits: 0
          }).format(value)
          return returnedCurrencyNumber
        }
      },
    },
  ];

  const customToolbar = () => {

    const emailButtonProps = {
      children: <Mail />
    }

    return <EmailExportButton emailButtonProps={emailButtonProps} columns={columns} rows={data} />
  }
  
  const options = {
    elevation: 0,
    filter: false,
    pagination: false,
    sort: false,
    search: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    print: true,
    download: true,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: "No calculation done.",
      }
    },
    customToolbar,
    // customHeadRender
  };

  return <>
    <div className='rounded border border-zinc-300 border-solid'>
      <ThemeProvider theme={theme}>
        <MUIDataTable data={data} columns={columns} options={options} />
      </ThemeProvider>
    </div>
    <Typography className='pt-1.5 text-xs italic text-right text-28east-gray'>*These figures show an estimated cost based on the volumes provided here and are not based on the actual usage of your account</Typography>
  </>
}

export default CalculationGrid;
