import { useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CalculationGrid from './CalculationGrid';


function CalculationGridPanel({data, currentExpandedPanel, handleChange}) {

  return (
    <div>
    <Accordion expanded={currentExpandedPanel === 'calculationGrid'} onChange={handleChange('calculationGrid')} elevation={0} variant="outlined" className='p-2 mb-8  border-zinc-300'>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1bh-content' id='panel1bh-header'>
        <div>
          <Typography className='text-xl font-bold text-28east-gray'>
          Estimated costs
          </Typography>
          <Typography className='text-base text-28east-gray'>
          The estimated costs including the 28East discount are displayed below.
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails >
        <CalculationGrid data={data}/>
      </AccordionDetails>
    </Accordion>
  </div>
  )
}

export default CalculationGridPanel