import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import React, { useState } from 'react';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../../common/Navbar/Navbar';
import CalculationInputPanel from './CalculationInputPanel/CalculationInputPanel';
import CalculationGridPanel from './CalculationGridPanel/CalculationGridPanel';
import PageDescription from './PageDescription';
import useAuth from '../../hooks/useAuth';
import Login from '../LoginLayout/loginLayout';
import Calculator from '../../services/CalculationService';
import Estimator from '../../services/Estimator';
import useData from '../../hooks/useData';

// ? This component will handle all interaction logic of the calculator
function CalculatorLayout() {
  const { isLoggedIn, getData } = useAuth();
  const { exchangeRate } = useData()
  const [calculationData, setCalculationData] = useState()
  const [currentExpandedPanel, setCurrentExpandedPanel] = useState(false);

  const handleAccordionChange = (panelDescription) => (event, isExpanded) => {
    setCurrentExpandedPanel(isExpanded ? panelDescription : false);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setCurrentExpandedPanel('calculationGrid');
    setSubmitting(true);
    const data = await getData()
    const productArray = await Calculator(values, data, exchangeRate);
    const estimatedData = await Estimator(productArray, exchangeRate)
    setCalculationData(estimatedData)
    setSubmitting(false);
  };

  const handleClear = () => {
    setCalculationData([])
  }

  return (
    isLoggedIn ? (
      <>
        <Navbar position='sticky' />
        <ToastContainer />
        <Container className='lg:px-24' maxWidth="xl">
          <PageDescription />
          <Grid>
            <Grid item>
              <CalculationInputPanel handleSubmit={handleSubmit} handleClear={handleClear}/>
            </Grid>
            <Grid>
              <CalculationGridPanel data={calculationData} currentExpandedPanel={currentExpandedPanel} handleChange={handleAccordionChange} />
            </Grid>
          </Grid>
        </Container>
      </>
    ) : (
      <Login />
    )
  );
}

export default CalculatorLayout;
