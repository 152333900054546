import { useState , useRef } from 'react'

// import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Tooltip } from '@mui/material'
import { toast } from 'react-toastify'
import { LoadingButton } from '@mui/lab'
import useAuth from '../../hooks/useAuth'
import useToggle from '../../hooks/useToggle'
import { generateMinifiedHTMLTable, validateField, isValidEmail, hasError } from './email.helpers'
import { shareEmail } from './EmailService'
import useData from '../../hooks/useData'

// This function generates a 2D array from columns and rows, suitable for CSV export
const prepareData = (columns, rows, message) => {
  // Initialize the 2D array that will hold the CSV data
  const twoDArray = []

  // Extract the 'field' property from each column object to define the CSV headers
  const columnFields = columns.map(col => col.name)
  const columnLabels = columns.map(col => col.label)

  twoDArray.push(columnLabels)
  
  if(rows.length) {
    // Iterate over each row object to reorder its values according to columnFields
    rows.forEach(row => {
      // Create a new array for each row with values ordered according to columnFields
      const orderedRow = columnFields.map(field => row[field]?.toString() || `${row?.[field] || ''}`) // Use an empty string if the field is missing
      
      // Add the ordered row to the 2D array
      twoDArray.push(orderedRow)
    })
  } else {
    twoDArray.push([message || 'Not Feasible'])
  }


  // Return the completed 2D array
  return {headers: columnLabels, rows: twoDArray}
}

function EmailForm({isLoading, onClose}) {
  const [errors, setErrors] = useState({})
  const toInputRef = useRef(null)
  const ccInputRef = useRef(null)
  const subjectInputRef = useRef(null)

  const validateEmailInputs = ({target}) => {

    const newErrors = {...errors}

    const { value: toInputValue, name: toInputName } = toInputRef.current
    const { value: ccInputValue, name: ccInputName } = ccInputRef.current
  
    if(target.name === toInputName) {
      validateField(toInputName, toInputValue, newErrors, [
        { condition: value => {
          const emails = value.split(',')
          let isError = false
    
          emails.forEach(email => {
            if(!isValidEmail(email.trim())) {
              isError = true
            }
          })
    
          return isError
        }, message: 'Email Addresses field cannot contain malformed email addresses' },
      ])
    }

    if(target.name === ccInputName) {
      validateField(ccInputName, ccInputValue, newErrors, [
        { condition: value => {
          let isError = false

          if(value) {
            const emails = value.split(',')
      
            emails.forEach(email => {
              if(!isValidEmail(email.trim())) {
                isError = true
              }
            })
      
          }

          return isError
        }, message: 'CC Address field cannot contain malformed email addresses' },
      ])
    }

    setErrors(newErrors)
  }

  return (<>
    <DialogTitle>
      Share 28East - Google Pricing Estimates via Email
    </DialogTitle>
    <DialogContent>
      <Grid container rowGap={2}>
        <TextField
          autoFocus
          fullWidth
          required
          error={errors?.to?.error}
          helperText={errors?.to?.message || 'Comma delimited list of email address'}
          id="to-input"
          inputRef={toInputRef}
          label="Email Addresses"
          margin="dense"
          name="to"
          type="text"
          variant="outlined"
          onChange={validateEmailInputs}
        />
        <TextField
          fullWidth
          error={errors?.cc?.error}
          helperText={errors?.cc?.message || 'Comma delimited list of email address '}
          id="cc-input"
          inputRef={ccInputRef}
          label="CC Address"
          margin="dense"
          name="cc"
          type="text"
          variant="outlined"
          onChange={validateEmailInputs}
        />
        <TextField
          fullWidth
          multiline
          required
          defaultValue={`28East - Google Pricing Estimates on ${new Date().toDateString()}`}
          helperText='Enter the subject of the email.'
          id="subject-input"
          inputRef={subjectInputRef}
          label="Subject"
          margin="dense"
          name="subject"
          type="text"
          variant="outlined"
        />
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <LoadingButton loading={isLoading} disabled={hasError(errors)} type='submit'>Share</LoadingButton> 
    </DialogActions>
  </>)
}

function EmailExportButton({ emailButtonProps={}, columns=[], rows=[]}) {
  const [isOpen, toggleIsOpen] = useToggle(false)
  const [isLoading, toggleIsLoading] = useToggle()
  const { exchangeRate } = useData()

  const { user } = useAuth()

  const onSubmit = async (event) => {
    event.preventDefault()

    toggleIsLoading()

    const formData = new FormData(event.currentTarget)
    const formJson = Object.fromEntries((formData).entries())

    try {

      if(!rows?.length > 0) {
        throw Error('error/no-table-data')
      }

      const tableTitle = '28East - Google Pricing Estimates'
      
      const html = generateMinifiedHTMLTable({title: tableTitle, columns, rows, user: user.user, exchangeRate})
      
      // filter out id rows from the row data object and sort row data to match the order set by the columns
      const {headers, rows: orderedRows } = prepareData(columns, rows)

      formJson.html = html
      formJson.data = {
        name: `${tableTitle} on ${new Date().toLocaleString()}`,
        headers,
        format: 'csv',
        content: orderedRows
      }

      const response = await shareEmail(formJson)

      if(response.data.success && response.data.message === 'Ok') {
        toast.success('Shared Calculation Estimates successfully', {
          position: toast.POSITION.TOP_RIGHT
        })
      } else {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
      clearState()        
    } catch (error) {
      if(error.message === 'error/no-table-data') {
        toast.info('Please make a calculation before attempting to send an email', {
          position: toast.POSITION.TOP_RIGHT
        })
        clearState()

        return
      }
      toast.error('Failed to share Calculation Estimates, it might be a network issue', {
        position: toast.POSITION.TOP_RIGHT
      })

      clearState()   
    }    
  }

  const clearState = () => {
    toggleIsLoading()
    toggleIsOpen()
  }

  const onClose = () => {
    toggleIsOpen()
  }

  return (
    <>
      <Dialog
        component='form'
        open={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
      >
        <EmailForm isLoading={isLoading} onClose={onClose}/>
      </Dialog>
      <Tooltip title='Share via Email'>
        <IconButton 
          {...emailButtonProps}
          onClick={toggleIsOpen}
        />
      </Tooltip>
    </>
  )
}

export default EmailExportButton